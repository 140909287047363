import React, { useEffect, useState } from 'react';
import { Box, Button, VStack, Fade } from '@chakra-ui/react';

import WavesAnimation from '../components/animations/WavesAnimation';
import LogoAnimation from '../components/animations/LogoAnimation';
import useCableStore from '../hooks/useCableStore';
import useNavigationStore from '../hooks/useNavigationStore';
import useGameroomStore from '../hooks/useGameroomStore';

import paths from '../constants/paths';

const Home = () => {
  const [isClickable, setIsClickable] = useState(false);
  const { navigateTo } = useNavigationStore();
  const { destroy } = useCableStore();
  const { clearGameroom } = useGameroomStore();

  useEffect(() => {
    localStorage.clear();
    clearGameroom();
    destroy();
  }, []);

  return (
    <Box
      height="100vh"
      position="relative"
      overflow="hidden"
      bg="main.purple.darkest"
    >
      <WavesAnimation />
      <Box
        position="relative"
        width="fit-content"
        margin="auto"
        paddingX="2rem"
        minW="42rem"
      >
        <LogoAnimation />
        <Fade
          in={true}
          transition={{ enter: { duration: 1, delay: 2.65 } }}
          onAnimationComplete={() => setIsClickable(true)}
        >
          <VStack
            gap="0.8rem"
            position="absolute"
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            width="100%"
            pointerEvents={isClickable ? 'auto' : 'none'}
          >
            <Button
              scheme="main.purple"
              onClick={() => navigateTo(paths.joinGameroom)}
            >
              Play
            </Button>
            <Button
              variant="outline"
              scheme="main.purple"
              onClick={() => navigateTo(paths.createGameroom)}
            >
              Create room
            </Button>
          </VStack>
        </Fade>
      </Box>
    </Box>
  );
};

export default Home;
