import React from 'react';
import Lottie from 'lottie-react';

import animationData from '../../assets/animations/bubbles.json';

const BubbleAnimation = () => {
  const style = {
    width: '100%',
    height: '100%',
  };

  return <Lottie animationData={animationData} style={style} />;
};

export default BubbleAnimation;
