const paths = {
  createGameroom: '/room/create',
  joinGameroom: '/room/join',
  awaiting: '/room/awaiting',
  round: '/round',
  result: '/round/result',
  answers: '/round/answers',
  packs: '/room/packs',
  scores: '/room/scores',
  root: '/',
};

export default paths;
