const sharedProps = {
  border: '2px',
};

const invalidProps = {
  ...sharedProps,
  borderColor: 'secondary.pink.light',
  _hover: {
    ...sharedProps,
    borderColor: 'secondary.pink.light',
  },
};

const Input = {
  sizes: {
    lg: {
      field: {
        fontSize: '1.6rem',
        lineHeight: '1.741rem',
        width: '27.9rem',
        height: '4.4rem',
        fontWeight: 400,
        borderRadius: '0.5rem',
      },
    },
  },
  variants: {
    custom: {
      field: {
        bg: 'main.white',
        textAlign: 'center',
        color: 'main.black',
        _hover: {
          ...sharedProps,
          borderColor: 'main.purple.base',
        },
        _invalid: invalidProps,
        _focusVisible: {
          _invalid: invalidProps,
          borderColor: 'main.purple.light',
        },
      },
    },
  },
  defaultProps: {
    size: 'lg',
    focusBorderColor: 'main.purple.light',
    errorBorderColor: 'secondary.pink.light',
  },
};

export default Input;
