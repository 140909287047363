import React from 'react';
import PropTypes from 'prop-types';

import { Center, Text } from '@chakra-ui/react';

import LogOut from '../assets/Logout.svg';

const LeaveGameButton = ({ onClick, isAdmin }) => {
  return (
    <Center
      onClick={onClick}
      position="absolute"
      bottom="3.2rem"
      left="0"
      right="0"
      gap="1rem"
      _hover={{ cursor: 'pointer' }}
    >
      <img src={LogOut} alt="logout" />
      <Text textColor="secondary.pink.light" variant="leave">
        {isAdmin ? 'finish game' : 'leave game'}
      </Text>
    </Center>
  );
};

export default LeaveGameButton;

LeaveGameButton.propTypes = {
  isAdmin: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
