import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'title',
  'description',
]);

const commonStyles = {
  description: {
    fontSize: '1.8rem',
    fontWeight: 'semibold',
  },
};

const TitleDescription = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      textAlign: 'center',
    },
    title: {
      color: 'secondary.yellow.base',
      fontWeight: 'semibold',
      fontSize: '2.4rem',
      lineHeight: '2.611rem',
      marginBottom: '0.8rem',
    },
    description: {
      color: 'main.white',
      fontSize: '1.8rem',
      lineHeight: '1.92rem',
      fontWeight: 'normal',
    },
  },
  variants: {
    answer: (props) => ({
      title: {
        color: props.isCorrect ? 'secondary.green' : 'secondary.pink.light',
        fontSize: '3.2rem',
        marginBottom: '2.4rem',
      },
      ...commonStyles,
    }),
    answers: {
      container: {
        gap: '2.4rem',
      },
      description: {
        fontWeight: 'semibold',
        lineHeight: '1.959rem',
      },
    },
    waiting: {
      title: {
        fontSize: '2.4rem',
        marginBottom: '3.2rem',
      },
      ...commonStyles,
    },
    loading: {
      title: {
        fontSize: '2.4rem',
      },
      description: {
        fontSize: '1.8rem',
      },
    },
  },
});

export default TitleDescription;
