import React, { useEffect, useState } from 'react';
import { Center, Stack, Button, Box, Text, Flex } from '@chakra-ui/react';

import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useCableStore from '../hooks/useCableStore';
import useNavigationStore from '../hooks/useNavigationStore';
import useGameroomStore from '../hooks/useGameroomStore';

import { AWAITING_MESSAGE, GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import { GAMEROOM_PICKING_NEW_PACK } from '../constants/events';
import paths from '../constants/paths';

import Modal from '../components/Modal';
import PlayerList from '../components/PlayerList';
import TitleDescription from '../components/TitleDescription';
import RoomCode from '../components/RoomCode';

import { openModal, closedModal } from '../utils/modal';

const AwaitingPlayers = () => {
  const { navigateTo } = useNavigationStore();

  const [footerInfo, setFooterInfo] = useState(
    'Waiting for other players to join...'
  );

  const { id: playerId, admin } = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const [modalInfo, setModalInfo] = useState(closedModal());
  const players = useGameroomStore(
    (state) =>
      state.gameroom?.players.map((player) => ({
        ...player,
        isAdmin: player.admin,
        isCurrentPlayer: player.id === playerId,
      })) ?? []
  );

  const maxPlayers = 50;
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const { setMessageHandler } = useCableStore();

  const { mutate: destroyGameroom } = useDestroyGameroom();

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const handleLeave = async () => {
    if (admin) {
      destroyPlayer(
        { gameroomKey, playerId },
        { onSuccess: () => destroyGameroom({ gameroomKey }) }
      );
    } else {
      destroyPlayer({ gameroomKey, playerId }, {});
    }
  };

  const handleStartGame = () => {
    navigateTo(paths.packs);
  };

  useEffect(() => {
    setFooterInfo(
      localStorage.getItem(AWAITING_MESSAGE) ??
        'Waiting for other players to join...'
    );

    return () => {
      localStorage.removeItem(AWAITING_MESSAGE);
    };
  }, []);

  useEffect(() => {
    setMessageHandler(async (message) => {
      switch (message?.event) {
        case GAMEROOM_PICKING_NEW_PACK:
          localStorage.setItem(AWAITING_MESSAGE, 'The game is starting...');
          setFooterInfo(localStorage.getItem(AWAITING_MESSAGE));
          break;
        default:
      }
    });
  }, []);

  return (
    <Center height="100vh">
      <Flex align="center" direction="column" gap="2.4rem">
        <Stack
          bg="main.purple.darker"
          paddingY="2.4rem"
          paddingX="3.2rem"
          gap="1rem"
          borderRadius="2rem"
          height={admin ? '52.572rem' : '48.572rem'}
        >
          <Stack
            width="27.9rem"
            height="47.972rem"
            gap="3.2rem"
            alignItems="center"
          >
            <Box position="relative">
              <TitleDescription
                title="Players"
                description="After you start the game, no more players will be able to join."
              />
              <Text variant="playerCount">
                ({players.length}/{maxPlayers})
              </Text>
            </Box>
            <RoomCode code={gameroomKey} copyable />
            <PlayerList players={players} />
          </Stack>

          <Stack width="27.9rem" height="9rem" gap="0.8rem" justify="end">
            {admin && (
              <Button
                variant="solid"
                scheme="main.purple"
                onClick={handleStartGame}
              >
                {'start game'}
              </Button>
            )}
            <Button
              variant="outline"
              scheme="secondary.pink"
              onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            >
              {admin ? 'cancel game' : 'leave game'}
            </Button>
          </Stack>
        </Stack>
        {!admin && (
          <Text color="white" variant="footer">
            {footerInfo}
          </Text>
        )}
      </Flex>

      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default AwaitingPlayers;
