import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'animationContainer',
  'content',
]);

const Loading = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      width: '100%',
      height: '100%',
      borderRadius: '2rem',
      padding: '2.4rem',
      justifyContent: 'center',
      position: 'relative',
    },
    animationContainer: {
      position: 'absolute',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      overflow: 'hidden',
    },
    content: {
      paddingTop: '58rem',
      alignItems: 'center',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '5.4rem',
    },
  },
});

export default Loading;
