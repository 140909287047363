import { useMutation } from '@tanstack/react-query';

import { createPlayer } from '../api/player';
import createDevice from '../api/device';

import { DEVICE_UUID, GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';

const useJoinGameroom = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async ({ username, gameroomKey }) => {
      const device = await createDevice(localStorage.getItem(DEVICE_UUID));
      const player = await createPlayer(gameroomKey, {
        username,
        deviceId: device.id,
      });

      return { player, device };
    },
    onSuccess: ({ player, device }) => {
      localStorage.setItem(DEVICE_UUID, device.deviceUuid);
      localStorage.setItem(GAMEROOM_KEY, player.gameroomKey);

      localStorage.setItem(
        PLAYER_INFO,
        JSON.stringify({
          id: player.id,
          username: player.username,
          admin: player.admin,
        })
      );
      onSuccess();
    },
    onError,
  });
};
export default useJoinGameroom;
