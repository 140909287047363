import { useMutation } from '@tanstack/react-query';

import { finishGame } from '../api/game';

const useFinishGame = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameroomKey, gameId }) => finishGame(gameroomKey, gameId),
    onSuccess,
  });
};

export default useFinishGame;
