import React, { useState } from 'react';

import {
  Button,
  Flex,
  Text,
  Stack,
  Box,
  useDisclosure,
  Grid,
} from '@chakra-ui/react';

import PlayerItem from '../components/PlayerItem';

import Input from '../components/Input';
import Persona from '../components/Persona';
import Pack from '../components/Pack';
import BackgroundBox from '../components/BackgroundBox';
import TitleDescription from '../components/TitleDescription';
import Modal from '../components/Modal';
import RoomCode from '../components/RoomCode';
import Loading from '../components/Loading';

const Components = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [inputValue, setInputValue] = useState('');

  const handleCheckboxConfirm = (isChecked) => {
    console.log('Confirmed with "Don\'t ask again" set to:', isChecked);
  };

  const [selectedPersona, setselectedPersona] = useState(null);

  const handleImageClick = (cardName) => {
    setselectedPersona(selectedPersona === cardName ? null : cardName);
  };

  return (
    <Flex
      flexDirection="column"
      bgColor="main.purple.darkest"
      padding="4rem"
      gap="20px"
      textAlign="left"
      align={'start'}
    >
      <Text fontSize="3rem" color="main.white">
        Buttons
      </Text>
      <Flex flexWrap="wrap" gap="2rem">
        <Button variant="solid" scheme="main.purple">
          Solid Light Purple
        </Button>
        <Button variant="solid" scheme="main.purple" isDisabled>
          Solid Light Purple Disabled
        </Button>
        <Button variant="outline" scheme="main.purple">
          Outline Light Purple
        </Button>
        <Button variant="outline" scheme="main.purple" isDisabled>
          Outline Light Purple Disabled
        </Button>
        <Button variant="solid" scheme="secondary.pink">
          Solid Red
        </Button>
        <Button variant="solid" scheme="secondary.pink" isDisabled>
          Solid Red Disabled
        </Button>
        <Button variant="outline" scheme="secondary.pink">
          Outline Red
        </Button>
        <Button variant="outline" scheme="secondary.pink" isDisabled>
          Outline Red Disabled
        </Button>
      </Flex>
      <Text fontSize="3rem" color="main.white">
        Inputs
      </Text>
      <Flex flexWrap="wrap" gap="2rem">
        <Input
          placeholder="Placeholder"
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
          label="Field"
        />
        <Input
          placeholder="placeholder"
          onChange={() => {}}
          value="Error value"
          label="Field with error"
          errors="Error"
        />
      </Flex>
      <Text fontSize="3rem" color="main.white">
        Persona
      </Text>
      <Text fontSize="1.5rem" color="main.white">
        Clickcable
      </Text>
      <Flex flexWrap="wrap" gap="2rem" align={'center'}>
        <Persona
          name="Adolf Hitler"
          color="main.purple.light"
          secondaryColor="main.purple.base"
          variant={
            selectedPersona === 'Adolf Hitler' ? 'selected' : 'notSelected'
          }
          onImageClick={() => handleImageClick('Adolf Hitler')}
        />
        <Persona
          name="Taylor Swift"
          color="main.purple.light"
          secondaryColor="main.purple.base"
          variant={
            selectedPersona === 'Taylor Swift' ? 'selected' : 'notSelected'
          }
          onImageClick={() => handleImageClick('Taylor Swift')}
        />
      </Flex>
      <Text fontSize="1.5rem" color="main.white">
        Not Clickcable
      </Text>
      <Flex flexWrap="wrap" gap="2rem" align={'center'}>
        <Persona
          name="..."
          color="main.purple.light"
          secondaryColor="main.purple.base"
        />
        <Persona
          name="Taylor Swift"
          color="main.purple.light"
          secondaryColor="main.purple.base"
        />
      </Flex>

      <Pack
        pack={{
          image: 'assets/placeholders/persona.svg',
          personaA: { name: 'ola', color: 'red' },
          personaB: { name: 'hello', color: 'blue' },
        }}
      />
      <Text fontSize="3rem" color="main.white">
        Quote
      </Text>
      <Text variant="quote">
        {`"I'm intimidated by the fear of being average. I'm intimidated by the
        fear of being average. I'm intimidated by the fear of being average."`}
      </Text>
      <Text fontSize="2rem" color="main.white">
        Example on screen
      </Text>
      <Box
        background="main.purple.darker"
        borderRadius="2rem"
        width="42rem"
        height="35rem"
        paddingX="3.2rem"
        paddingTop="2.4rem"
      >
        <Text variant="quote">
          {`"I'm intimidated by the fear of being average."`}
        </Text>
      </Box>
      <Text fontSize="3rem" color="main.white">
        Title Description
      </Text>
      <Flex flexWrap="wrap" gap="2rem" width="42rem">
        <BackgroundBox>
          <Stack spacing="2.4rem">
            <TitleDescription
              title="You’re a genius!"
              description={`"I'm intimidated by the fear of being average."`}
              isCorrect={true}
              variant="answer"
            />
            <Persona name="Taylor Swift" />
          </Stack>
        </BackgroundBox>

        <BackgroundBox>
          <Stack spacing="2.4rem">
            <TitleDescription
              title="Oh, snap!"
              description={`"I'm intimidated by the fear of being average."`}
              isCorrect={false}
              variant="answer"
            />
            <Persona name="Taylor Swift" />
          </Stack>
        </BackgroundBox>

        <BackgroundBox>
          <TitleDescription
            title="Waiting for other players..."
            description="“I'm intimidated by the fear of being average.”"
            variant="waiting"
          />
        </BackgroundBox>

        <BackgroundBox>
          <TitleDescription
            title="Create room"
            description="Insert your nickname to start inviting other players."
          />
        </BackgroundBox>

        <TitleDescription
          title="Reveal answer?"
          description="Other players won't be able to respond anymore."
        />
      </Flex>
      <Text fontSize="3rem" color="main.white" marginTop="5rem">
        Player Component
      </Text>
      <Stack gap="1rem" background="main.purple.darker" padding="2rem">
        <Text fontSize="2rem" color="main.white" marginTop="1rem">
          Leaderboard
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="1rem" columnGap="10rem">
          <div width="34.3rem">
            <Text textColor="white" marginY="1rem">
              Winner Me
            </Text>
            <PlayerItem
              username="winner_me"
              isCurrentPlayer
              roomProps={{ value: 50, position: 'firstPlace' }}
              position={'firstPlace'}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Winner Other
            </Text>
            <PlayerItem
              username="winner"
              roomProps={{ value: 50, position: 'firstPlace' }}
              position={'firstPlace'}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin Me
            </Text>
            <PlayerItem
              username="admin_me"
              isCurrentPlayer
              isAdmin
              roomProps={{ value: 50, position: 'other' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin Other
            </Text>
            <PlayerItem
              username="admin"
              isAdmin
              roomProps={{ value: 50, position: 'other' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player Me
            </Text>
            <PlayerItem
              username="player_me"
              isCurrentPlayer
              roomProps={{ value: 50, position: 'other' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player Other
            </Text>
            <PlayerItem
              username="player"
              roomProps={{ value: 50, position: 'other' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Loser Me
            </Text>
            <PlayerItem
              username="loser_me"
              isCurrentPlayer
              roomProps={{ value: 50, position: 'lastPlace' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Loser Other
            </Text>
            <PlayerItem
              username="loser"
              roomProps={{ value: 50, position: 'lastPlace' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              New Player Me
            </Text>
            <PlayerItem
              username="new_player_me"
              isCurrentPlayer
              roomProps={{ value: '-', position: 'other' }}
              variant="leaderBoard"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              New Player Other
            </Text>
            <PlayerItem
              username="new_player"
              roomProps={{ value: '-', position: 'other' }}
              variant="leaderBoard"
            />
          </div>
        </Grid>

        <Text fontSize="2rem" color="main.white" marginTop="3rem">
          Waiting room
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="1rem" columnGap="10rem">
          <div>
            <Text textColor="white" marginY="1rem">
              Player Me
            </Text>
            <PlayerItem username="player_me" isCurrentPlayer />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player other
            </Text>
            <PlayerItem username="player" />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin Me
            </Text>
            <PlayerItem username="admin_me" isAdmin isCurrentPlayer />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin Other
            </Text>
            <PlayerItem username="admin" isAdmin />
          </div>
        </Grid>

        <Text fontSize="2rem" color="main.white" marginTop="3rem">
          Answer room
        </Text>
        <Grid templateColumns="repeat(2, 1fr)" gap="1rem" columnGap="10rem">
          <div>
            <Text textColor="white" marginY="1rem">
              Player unanswered
            </Text>
            <PlayerItem
              username="playerone"
              roomProps={{ status: 'unanswered' }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin unanswered
            </Text>
            <PlayerItem
              username="playerone"
              isAdmin
              roomProps={{ status: 'unanswered' }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player me answered
            </Text>
            <PlayerItem
              username="playerone"
              isCurrentPlayer
              roomProps={{
                status: 'answered',
                persona: { name: 'Taylor Swift', color: '#F05483' },
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player other answered
            </Text>
            <PlayerItem
              username="playerone"
              roomProps={{
                status: 'answered',
                persona: {
                  name: 'Taylor Swift',
                  color: 'secondary.pink.light',
                },
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin me answered
            </Text>
            <PlayerItem
              username="playerone"
              isAdmin
              isCurrentPlayer
              roomProps={{
                status: 'answered',
                persona: { name: 'Taylor Swift', color: '#F05483' },
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin other answered
            </Text>
            <PlayerItem
              username="playerone"
              isAdmin
              roomProps={{
                status: 'answered',
                persona: {
                  name: 'Taylor Swift',
                  color: 'secondary.pink.light',
                },
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player me correct
            </Text>
            <PlayerItem
              username="playerone"
              isCurrentPlayer
              roomProps={{
                status: 'correct',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player other correct
            </Text>
            <PlayerItem
              username="playerone"
              roomProps={{
                status: 'correct',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player me incorrect
            </Text>
            <PlayerItem
              username="playerone"
              isCurrentPlayer
              roomProps={{
                status: 'incorrect',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Player other incorrect
            </Text>
            <PlayerItem
              username="playerone"
              roomProps={{
                status: 'incorrect',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin me correct
            </Text>
            <PlayerItem
              username="playerone"
              isCurrentPlayer
              isAdmin
              roomProps={{
                status: 'correct',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin other correct
            </Text>
            <PlayerItem
              username="playerone"
              isAdmin
              roomProps={{
                status: 'correct',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin me incorrect
            </Text>
            <PlayerItem
              username="playerone"
              isCurrentPlayer
              isAdmin
              roomProps={{
                status: 'incorrect',
              }}
              variant="answer"
            />
          </div>
          <div>
            <Text textColor="white" marginY="1rem">
              Admin other incorrect
            </Text>
            <PlayerItem
              username="playerone"
              isAdmin
              roomProps={{
                status: 'incorrect',
              }}
              variant="answer"
            />
          </div>
        </Grid>
      </Stack>
      <Text fontSize="3rem" color="main.white">
        Modal
      </Text>
      <Flex flexWrap="wrap" gap="2rem" width="full">
        <Button variant="solid" scheme="main.purple" onClick={onOpen}>
          Reveal Answer
        </Button>
        <Modal
          title="Reveal answer?"
          description="Other players won't be able to respond anymore."
          confirmText="CONFIRM"
          showCheckbox
          onConfirm={handleCheckboxConfirm}
          onBack={undefined}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Flex>
      <RoomCode code="GUS10" copyable={true} />
      <Text fontSize="3rem" color="main.white" marginRight="100rem">
        Loading
      </Text>
      <Box height="109rem" width="full">
        <Loading
          title="Trying to reconnect..."
          description="Make sure you’re online."
          leaveGameButton
        />
      </Box>
    </Flex>
  );
};

export default Components;
