import { useQuery } from '@tanstack/react-query';

import getPacks from '../api/packs';
import { GAMEROOM_KEY } from '../constants/key';

const useGetPacks = () => {
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);

  return useQuery({
    queryKey: ['packs', gameroomKey],
    queryFn: async () => {
      const packs = await getPacks(gameroomKey);

      return packs.sort((a, b) => a.position - b.position);
    },
  });
};

export default useGetPacks;
