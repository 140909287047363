import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';

import logoAndBubblesAnimation from '../../assets/animations/logoandbubbles.json';

const HomeAnimation = () => {
  const style = {
    maxWidth: '60rem',
    margin: 'auto',
    zIndex: 0,
  };

  const logoRef = useRef();

  useEffect(() => {
    if (logoRef.current) {
      logoRef.current.setSpeed(1.2);
    }
  }, []);

  return (
    <Lottie
      animationData={logoAndBubblesAnimation}
      style={style}
      loop={false}
      lottieRef={logoRef}
    />
  );
};

export default HomeAnimation;
