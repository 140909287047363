import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, useMultiStyleConfig } from '@chakra-ui/react';

import TitleDescription from './TitleDescription';
import BubbleAnimation from './animations/BubbleAnimation';

const Loading = ({ title, description, leaveGameButton, onClickButton }) => {
  const styles = useMultiStyleConfig('Loading');
  return (
    <>
      <Box sx={{ ...styles.container }}>
        <Box sx={{ ...styles.animationContainer }}>
          <BubbleAnimation />
        </Box>
        <Box sx={{ ...styles.content }}>
          <TitleDescription
            title={title}
            description={description}
            variant="loading"
          />
          {leaveGameButton && (
            <Button
              variant="outline"
              scheme="main.purple"
              onClick={onClickButton}
            >
              LEAVE GAME
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

Loading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  leaveGameButton: PropTypes.bool,
  onClickButton: PropTypes.func,
};

export default Loading;
