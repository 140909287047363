import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import camelcaseKeys from 'camelcase-keys';

import useCableStore from '../hooks/useCableStore';
import useGameroomStore from '../hooks/useGameroomStore';

import { GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import {
  GAMEROOM_CLOSED,
  GAMEROOM_PLAYER_JOINED,
  GAMEROOM_PLAYER_LEFT,
  GAME_CANCELED,
  GAME_STARTED,
  GAME_ROUND_STARTED,
  GAME_ROUND_FINISHED,
  GAME_ROUND_ANSWER_CREATED,
  GAME_FINISHED,
} from '../constants/events';

import paths from '../constants/paths';
import useNavigationStore from '../hooks/useNavigationStore';

const ConnectCableWrapper = () => {
  const { create, subscribe, message, messageHandler, clearMessage } =
    useCableStore();

  const playerInfo = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const addPlayerToGameroom = useGameroomStore(
    (state) => state.addPlayerToGameroom
  );
  const addCurrentGameToGameroom = useGameroomStore(
    (state) => state.addCurrentGameToGameroom
  );
  const addRoundToGameroom = useGameroomStore(
    (state) => state.addRoundToGameroom
  );
  const finishGameroomCurrentRound = useGameroomStore(
    (state) => state.finishGameroomCurrentRound
  );
  const addAnswerToGameroom = useGameroomStore(
    (state) => state.addAnswerToGameroom
  );
  const removePlayerFromGameroom = useGameroomStore(
    (state) => state.removePlayerFromGameroom
  );
  const updateGameroom = useGameroomStore((state) => state.updateGameroom);

  const { navigateTo } = useNavigationStore();

  useEffect(() => {
    create();
    if (playerInfo) {
      subscribe(localStorage.getItem(GAMEROOM_KEY), playerInfo.id);
    }
  }, []);

  useEffect(() => {
    if (message) {
      const processedMessage = camelcaseKeys(message, { deep: true });

      messageHandler(processedMessage);

      switch (processedMessage?.event) {
        case GAMEROOM_PLAYER_JOINED:
          addPlayerToGameroom(processedMessage);
          break;
        case GAMEROOM_PLAYER_LEFT:
          removePlayerFromGameroom(processedMessage);
          if (processedMessage.id === playerInfo.id) navigateTo(paths.root);
          break;
        case GAME_STARTED:
          addCurrentGameToGameroom(processedMessage);
          break;
        case GAME_ROUND_STARTED:
          navigateTo(paths.round);
          addRoundToGameroom(processedMessage);
          break;
        case GAME_ROUND_FINISHED:
          navigateTo(paths.result);
          finishGameroomCurrentRound(processedMessage);
          break;
        case GAME_ROUND_ANSWER_CREATED:
          addAnswerToGameroom(processedMessage);
          if (processedMessage.playerId === playerInfo.id) {
            navigateTo(paths.answers);
          }
          break;
        case GAME_CANCELED:
        case GAME_FINISHED:
          navigateTo(paths.scores);
          addCurrentGameToGameroom(processedMessage);
          break;
        case GAMEROOM_CLOSED:
          navigateTo(paths.root);
          updateGameroom(processedMessage);
          break;
        default:
      }
      clearMessage();
    }
  }, [message?.event]);

  return <Outlet />;
};

export default ConnectCableWrapper;
