import { useMutation } from '@tanstack/react-query';

import { createGameroom } from '../api/gameroom';
import { createPlayer } from '../api/player';
import createDevice from '../api/device';
import { DEVICE_UUID, GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';

const useCreateGameroom = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (username) => {
      const device = await createDevice(localStorage.getItem(DEVICE_UUID));
      const gameroom = await createGameroom();
      const player = await createPlayer(gameroom.gameroomKey, {
        username,
        deviceId: device.id,
      });

      return { player, device };
    },
    onSuccess: ({ player, device }) => {
      localStorage.setItem(DEVICE_UUID, device.deviceUuid);
      localStorage.setItem(GAMEROOM_KEY, player.gameroomKey);

      localStorage.setItem(
        PLAYER_INFO,
        JSON.stringify({
          id: player.id,
          username: player.username,
          admin: player.admin,
        })
      );
      onSuccess();
    },
    onError,
  });
};
export default useCreateGameroom;
