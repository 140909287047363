import { useMutation } from '@tanstack/react-query';

import { destroyPlayer } from '../api/player';

const useDestroyPlayer = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameroomKey, playerId }) =>
      destroyPlayer(gameroomKey, playerId),
    onSuccess,
  });
};

export default useDestroyPlayer;
