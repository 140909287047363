const Checkbox = {
  baseStyle: {
    control: {
      border: '0.2rem solid',
      borderRadius: '0.2rem',
      borderColor: 'secondary.yellow.base',
      _disabled: {
        borderColor: 'secondary.yellow.base',
        _hover: {
          borderColor: 'secondary.yellow.dark',
          bg: 'secondary.yellow.dark',
        },
      },
      _checked: {
        bg: 'secondary.yellow.base',
        color: 'main.purple.dark',
        borderColor: 'secondary.yellow.base',
        _hover: {
          borderColor: 'secondary.yellow.dark',
          bg: 'secondary.yellow.dark',
        },
      },
    },
    label: {
      marginLeft: '0.8rem',
      textColor: 'main.white',
    },
    container: {
      marginTop: '3.2rem',
      marginX: '0.45rem',
    },
  },
  sizes: {
    xl: {
      control: {
        boxSize: '1.8rem',
      },
      label: {
        fontSize: '1.6rem',
      },
    },
  },
  defaultProps: {
    size: 'xl',
  },
};

export default Checkbox;
