import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@chakra-ui/react';

const BackgroundBox = ({ children }) => {
  return (
    <Box
      borderRadius="2rem"
      backgroundColor="main.purple.darker"
      width="100%"
      overflow="auto"
      paddingY="2.4rem"
      paddingX="3.2rem"
    >
      {children}
    </Box>
  );
};

BackgroundBox.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackgroundBox;
