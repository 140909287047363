import { create } from 'zustand';

const updatePlayers = (state, updateFn) => {
  const updatedGameroomPlayers = updateFn(state.gameroom.players);
  const updatedCurrentGamePlayers = state.gameroom.currentGame
    ? updateFn(state.gameroom.currentGame.players)
    : [];

  return {
    ...state.gameroom,
    players: updatedGameroomPlayers,
    currentGame: state.gameroom.currentGame
      ? {
          ...state.gameroom.currentGame,
          players: updatedCurrentGamePlayers,
        }
      : state.gameroom.currentGame,
  };
};

const useGameroomStore = create((set) => ({
  gameroom: null,

  addPlayerToGameroom: (player) =>
    set((state) => {
      const gameroom = updatePlayers(state, (players) => [...players, player]);
      return { ...state, gameroom };
    }),

  addAnswerToGameroom: (answer) =>
    set((state) => {
      if (!state.gameroom.currentGame) return state;

      const { currentGame } = state.gameroom;
      const { rounds } = currentGame;
      const currentRoundIndex = rounds.length - 1;
      const currentRound = rounds[currentRoundIndex];

      const updatedRound = {
        ...currentRound,
        answers: [...currentRound.answers, answer],
      };

      const updatedRounds = [
        ...rounds.slice(0, currentRoundIndex),
        updatedRound,
        ...rounds.slice(currentRoundIndex + 1),
      ];

      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: { ...currentGame, rounds: updatedRounds },
        },
      };
    }),

  addCurrentGameToGameroom: (currentGame) =>
    set((state) => {
      const gameroom = {
        ...state.gameroom,
        currentGame,
        players: currentGame.players,
      };
      return { ...state, gameroom };
    }),

  addRoundToGameroom: (round) =>
    set((state) => {
      const { rounds } = state.gameroom.currentGame;
      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: {
            ...state.gameroom.currentGame,
            rounds: [...rounds, round],
          },
        },
      };
    }),

  finishGameroomCurrentRound: (round) =>
    set((state) => {
      const { currentGame } = state.gameroom;
      const { rounds } = currentGame;
      const currentRoundIndex = rounds.length - 1;
      const updatedRounds = [
        ...rounds.slice(0, currentRoundIndex),
        round,
        ...rounds.slice(currentRoundIndex + 1),
      ];

      return {
        ...state,
        gameroom: {
          ...state.gameroom,
          currentGame: { ...currentGame, rounds: updatedRounds },
        },
      };
    }),

  removePlayerFromGameroom: (player) =>
    set((state) => {
      const hasCurrentGame = !!state.gameroom?.currentGame;

      if (hasCurrentGame) {
        const gameroom = updatePlayers(state, (players) =>
          players.map((oldPlayer) =>
            oldPlayer.username === player.username
              ? { ...oldPlayer, leftGameroomAt: new Date().toISOString() }
              : oldPlayer
          )
        );
        return { ...state, gameroom };
      }

      const gameroom = {
        ...state.gameroom,
        players: state.gameroom.players.filter(
          (oldPlayer) => oldPlayer.username !== player.username
        ),
      };
      return { ...state, gameroom };
    }),
  updateGameroom: (gameroom) =>
    set((state) => {
      return { ...state, gameroom };
    }),

  clearGameroom: () => set((state) => ({ ...state, gameroom: null })),
}));

export default useGameroomStore;
