import { useMutation } from '@tanstack/react-query';

import { finishRound } from '../api/round';

const useFinishRound = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameId, roundId }) => finishRound(gameId, roundId),
    onSuccess,
  });
};

export default useFinishRound;
