import { extendTheme } from '@chakra-ui/react';

import background from '../assets/background.svg';

import Button from './components/Button';
import PlayerList from './components/PlayerList';
import Input from './components/Input';
import { Form, FormError } from './components/Form';
import Pack from './components/Pack';
import Text from './components/Text';
import TitleDescription from './components/TitleDescription';
import PlayerItem from './components/PlayerItem';
import Checkbox from './components/Checkbox';
import Modal from './components/Modal';
import RoomCode from './components/RoomCode';
import Loading from './components/Loading';
import Persona from './components/Persona';
import Leaderboard from './components/Leaderboard';

import '@fontsource/archivo/400.css';
import '@fontsource/archivo/600.css';
import '@fontsource/archivo/800.css';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        minHeight: '100vh',
        width: '100vw',
        bg: `url("${background}")`,
        bgColor: 'main.purple.darkest',
        bgRepeat: 'no-repeat',
        bgPosition: 'left bottom',
        bgSize: 'cover',
        fontFamily: `'Archivo', sans-serif`,
        lineHeight: '120%',
        letterSpacing: '0.1px',
      },
      html: {
        fontSize: {
          base: '8px',
          sm: '10px',
          '2xl': '22px',
        },
        fontKerning: 'normal',
      },
      '.slick-track': {
        height: 'auto !important',
      },
    },
  },
  breakpoints: {
    base: '0px',
    sm: '768px',
    lg: '1680px',
    xl: '1920px',
    '2xl': '3840px',
  },
  fonts: {
    body: `'Archivo', sans-serif`,
    heading: `'Archivo', sans-serif`,
  },
  colors: {
    main: {
      black: '#000000',
      white: '#FFFFFF',
      purple: {
        lightAlpha40: '#8673FA66',
        light: '#8673FA',
        lightAlpha20: '#8673FA33',
        base: '#523F9F',
        dark: '#3F2F80',
        darker: '#2C1A50',
        darkerAlpha50: '#2C1A5080',
        darkest: '#170737',
        darkestAlpha70: '#170737B2',
      },
    },
    secondary: {
      green: '#BDE689',
      lilac: {
        base: '#B2A7FB',
        baseAlpha30: '#B2A7FB4D',
      },
      yellow: {
        base: '#FFC552',
        dark: '#BF830D',
      },
      pink: {
        light: '#F64A7E',
        base: '#D63566',
        dark: '#B3224E',
      },
    },
  },
  components: {
    Button,
    Input,
    Form,
    FormError,
    Pack,
    Text,
    TitleDescription,
    PlayerItem,
    Checkbox,
    Modal,
    RoomCode,
    Loading,
    Persona,
    PlayerList,
    Leaderboard,
  },
});

export default theme;
