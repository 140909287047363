import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Text, useMultiStyleConfig } from '@chakra-ui/react';

const TitleDescription = ({ title, description, variant, isCorrect }) => {
  const styles = useMultiStyleConfig('TitleDescription', {
    variant,
    isCorrect,
  });

  return (
    <Stack sx={styles.container}>
      <Text sx={{ ...styles.title, isCorrect: { isCorrect } }}>{title}</Text>
      <Text sx={styles.description}>{description}</Text>
    </Stack>
  );
};

TitleDescription.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  variant: PropTypes.string,
};

export default TitleDescription;
