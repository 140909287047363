import React from 'react';
import { Icon } from '@chakra-ui/react';

const Player = (props) => (
  <Icon viewBox="0 0 14 18" {...props}>
    <path
      fill="currentColor"
      d="M6.99905 7.84601C8.99624 7.84601 10.6153 6.23106 10.6153 4.23892C10.6153 2.24678 8.99624 0.631836 6.99905 0.631836C5.00186 0.631836 3.38281 2.24678 3.38281 4.23892C3.38281 6.23106 5.00186 7.84601 6.99905 7.84601Z"
    />
    <path
      fill="currentColor"
      d="M6.99905 7.84601C8.99624 7.84601 10.6153 6.23106 10.6153 4.23892C10.6153 2.24678 8.99624 0.631836 6.99905 0.631836C5.00186 0.631836 3.38281 2.24678 3.38281 4.23892C3.38281 6.23106 5.00186 7.84601 6.99905 7.84601Z"
    />
    <path
      fill="currentColor"
      d="M9.91882 8.84595C12.1661 8.84595 14 10.6753 14 12.9426V14.54C14 15.9055 12.8893 17.0134 11.5203 17.0134H2.4797C1.1107 17.0134 0 15.9055 0 14.54V12.9426C0 10.6753 1.83395 8.84595 4.10701 8.84595H9.91882Z"
    />
    <path
      fill="currentColor"
      d="M9.91882 8.84595C12.1661 8.84595 14 10.6753 14 12.9426V14.54C14 15.9055 12.8893 17.0134 11.5203 17.0134H2.4797C1.1107 17.0134 0 15.9055 0 14.54V12.9426C0 10.6753 1.83395 8.84595 4.10701 8.84595H9.91882Z"
    />
  </Icon>
);

export default Player;
