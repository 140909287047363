import { useMutation } from '@tanstack/react-query';

import { cancelGame } from '../api/game';

const useCancelGame = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameroomKey, gameId }) => cancelGame(gameroomKey, gameId),
    onSuccess,
  });
};

export default useCancelGame;
