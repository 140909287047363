import React, { useState, useEffect } from 'react';
import {
  Stack,
  Button,
  Box,
  Text,
  Center,
  useDisclosure,
  Flex,
} from '@chakra-ui/react';

import useGetPacks from '../hooks/useGetPacks';
import useCreateGame from '../hooks/useCreateGame';
import useDestroyGameroom from '../hooks/useDestroyGameroom';
import useBuildNextRound from '../hooks/useBuildNextRound';
import useNavigationStore from '../hooks/useNavigationStore';

import Loading from '../components/Loading';
import PackSlider from '../components/PackSlider';
import Modal from '../components/Modal';

import { PLAYER_INFO, GAMEROOM_KEY } from '../constants/key';
import paths from '../constants/paths';

const PackSelection = () => {
  const { navigateTo } = useNavigationStore();
  const [selectedPack, setSelectedPack] = useState(null);
  const { data: packs, isLoading } = useGetPacks();

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const player = JSON.parse(localStorage.getItem(PLAYER_INFO));

    if (!player) {
      navigateTo(paths.root);
      return;
    }

    if (!player.admin) {
      navigateTo(paths.awaiting);
    }
  }, [navigateTo]);

  const handlePackClick = (position) => {
    setSelectedPack((prevSelectedPackId) =>
      prevSelectedPackId === position ? null : position
    );
  };

  const { mutate: buildNextRound } = useBuildNextRound();

  const { mutate: createGame } = useCreateGame({
    onSuccess: ({ game }) => {
      const { id: gameId } = game;
      buildNextRound({ gameId });
    },
    onError: (error) => console.log(error),
  });

  const { mutate: destroyGameroom } = useDestroyGameroom({
    onSuccess: () => {},
  });

  const handleSubmit = async () => {
    if (selectedPack !== null) {
      createGame(packs[selectedPack].id);
    }
  };

  const handleCancelGame = async () => {
    const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
    destroyGameroom({ gameroomKey });
  };

  return (
    <Flex
      width="100vw"
      minW="42rem"
      minH="100vh"
      justifyContent="center"
      alignItems="center"
      padding="0.5rem"
    >
      {isLoading ? (
        <Loading title="Loading..." description="Wait a few moments." />
      ) : (
        <Stack gap="3.2rem" maxW="42rem" justifyContent="center">
          <Text variant="pageTitle">Packs</Text>
          {packs?.length > 0 ? (
            <Box>
              <PackSlider
                packs={packs}
                selectedPack={selectedPack}
                onClick={handlePackClick}
              />
            </Box>
          ) : (
            <Center color="white" fontSize="1.8rem" height="75%">
              No available packs.
            </Center>
          )}
          <Stack gap="0.8rem" alignItems="center">
            <Button
              variant="solid"
              scheme="main.purple"
              onClick={handleSubmit}
              isDisabled={selectedPack === null}
            >
              Play
            </Button>
            <Button variant="outline" scheme="main.purple" onClick={onOpen}>
              End game
            </Button>
          </Stack>
        </Stack>
      )}
      <Modal
        title="Cancel game?"
        description="Doing this will close the current game room."
        confirmText="Ok"
        onConfirm={handleCancelGame}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Flex>
  );
};

export default PackSelection;
