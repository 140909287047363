import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'code',
  'copy',
  'roomCodeText',
]);

const RoomCode = helpers.defineMultiStyleConfig({
  baseStyle: (props) => ({
    container: {
      justify: 'center',
      width: props.copyable ? '18.7rem' : '15.6rem',
      height: '3.4rem',
      borderRadius: '0.8rem',
      textAlign: 'center',
      bg: 'main.purple.lightAlpha40',
      paddingY: '0.8rem',
      paddingX: '1.6rem',
      gap: '0.8rem',
    },
    code: {
      fontWeight: 600,
      fontSize: '1.4rem',
      lineHeight: '1.523rem',
      letterSpacing: '5%',
      textTransform: 'uppercase',
      color: 'secondary.yellow.base',
    },
    copy: {
      objectFit: 'fill',
      width: '1.6rem',
      height: '1.6rem',
      _hover: {
        cursor: 'pointer',
        borderRadius: '0.5rem',
        padding: '0.1rem',
        bg: 'main.purple.light',
      },
    },
    roomCodeText: {
      fontWeight: 400,
      fontSize: '1.4rem',
      lineHeight: '1.523rem',
      textColor: 'white',
    },
  }),
});

export default RoomCode;
