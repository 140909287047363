import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Checkbox,
  Button,
} from '@chakra-ui/react';

import TitleDescription from './TitleDescription';

const Modal = ({
  title,
  description,
  confirmText,
  showCheckbox,
  onConfirm,
  isOpen,
  onClose,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleConfirm = () => {
    onConfirm(isChecked);
    onClose();
  };

  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <TitleDescription title={title} description={description} />
        </ModalBody>
        <ModalFooter>
          <Button variant="solid" scheme="main.purple" onClick={handleConfirm}>
            {confirmText}
          </Button>
          <Button variant="outline" scheme="main.purple" onClick={onClose}>
            Back
          </Button>
        </ModalFooter>
        {showCheckbox && (
          <Checkbox isChecked={isChecked} onChange={handleCheckboxChange}>
            Don&apos;t ask again in this gameroom.
          </Checkbox>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  confirmText: PropTypes.string,
  showCheckbox: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
