const Text = {
  variants: {
    quote: {
      fontSize: '2.4rem',
      fontWeight: 'semibold',
      textColor: 'white',
      textAlign: 'center',
      marginX: '1.6rem',
      marginY: '2.4rem',
      lineHeight: 'normal',
    },
    playerCount: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: '0.45rem',
      fontSize: '1.4rem',
      color: 'white',
      opacity: 0.5,
    },
    footer: {
      fontFamily: 'Archivo',
      fontSize: '1.8rem',
      fontWeight: '600',
      lineHeight: '1.958rem',
    },
    count: {
      fontWeight: 600,
      fontSize: '1.6rem',
      textAlign: 'center',
      textColor: 'white',
    },
    whoSaidIt: {
      fontSize: '2.0rem',
      fontWeight: 600,
      textAlign: 'center',
    },
    pageTitle: {
      fontSize: '2.4rem',
      fontWeight: '600',
      textAlign: 'center',
      textColor: 'secondary.yellow.base',
      lineHeight: 'normal',
    },
    leave: {
      fontSize: '1.6rem',
      align: 'center',
      fontWeight: 600,
      textTransform: 'uppercase',
    },
    completed: {
      fontSize: '3.2rem',
      fontWeight: '600',
      textAlign: 'center',
      textColor: 'secondary.yellow.base',
      lineHeight: 'normal',
    },
  },
};

export default Text;
