import { getApiClient } from '..';

const createGameroom = async () => {
  const response = await getApiClient().post('/gamerooms');
  return response.data;
};

const fetchGameroom = async (gameroomKey) => {
  const response = await getApiClient().get(`/gamerooms/${gameroomKey}`);
  return response.data;
};

const destroyGameroom = async (gameroomKey) => {
  const response = await getApiClient().delete(`/gamerooms/${gameroomKey}`);
  return response.data;
};

export { createGameroom, fetchGameroom, destroyGameroom };
