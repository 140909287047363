import React, { useRef, useState } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Circle } from '@chakra-ui/react';

import PropTypes from 'prop-types';

import Pack from './Pack';

import { PackShape } from '@utils/propsShape';

const PackSlider = ({ packs, selectedPack, onClick }) => {
  const sliderRef = useRef(null);
  const [index, setIndex] = useState(0);

  const settings = {
    vertical: true,
    verticalSwiping: true,
    swipe: true,
    swipeToSlide: true,
    infinite: false,
    dots: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dotsClass: null,
    beforeChange: (_, newIndex) => {
      setIndex(newIndex);
    },
    appendDots: () => (
      <Box
        position="absolute"
        top="50%"
        right="-2.4rem"
        transform="translateY(-50%)"
      >
        <Box
          as="ul"
          m="0"
          display="flex"
          flexDirection="column"
          cursor="pointer"
        >
          {packs.map((_, i) => (
            <Circle
              key={i}
              size="1rem"
              bg={
                i >= index && i < index + 3
                  ? 'secondary.yellow.base'
                  : 'main.purple.dark'
              }
              onClick={() => sliderRef.current.slickGoTo(i)}
              m="0.5rem 0"
            />
          ))}
        </Box>
      </Box>
    ),
    customPaging: () => <Box display="none" />,
  };

  const handleWheel = (e) => {
    if (e.deltaY < 0) {
      sliderRef.current.slickPrev();
    } else {
      sliderRef.current.slickNext();
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      position="relative"
      onWheel={handleWheel}
      my="-0.8rem"
    >
      <Box mx="auto">
        <Slider ref={sliderRef} {...settings}>
          {packs.map((pack, i) => (
            <Box key={i} my="0.8rem">
              <Pack
                pack={pack}
                onClick={() => onClick(i)}
                variant={selectedPack === i ? 'selected' : ''}
              />{' '}
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

PackSlider.propTypes = {
  packs: PropTypes.arrayOf(PackShape),
  selectedPack: PropTypes.number,
  onClick: PropTypes.func.isRequired,
};

export default PackSlider;
