import { useMutation } from '@tanstack/react-query';

import { buildNextRound } from '../api/round';

const useBuildNextRound = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameId }) => buildNextRound(gameId),
    onSuccess,
  });
};

export default useBuildNextRound;
