import React from 'react';
import { Icon } from '@chakra-ui/react';

const Incorrect = (props) => (
  <Icon viewBox="0 0 16 17" {...props}>
    <path
      fill="currentColor"
      d="M5.08061 8.35166L0.376953 3.39299L3.26715 0.358276L7.9897 5.29712L12.7123 0.358276L15.6213 3.39299L10.8988 8.35166L15.6213 13.3103L12.7123 16.3649L7.9897 11.4062L3.26715 16.3649L0.376953 13.3103L5.08061 8.35166Z"
    />
  </Icon>
);

export default Incorrect;
