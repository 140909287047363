import React from 'react';
import { Icon } from '@chakra-ui/react';

const Loser = (props) => (
  <Icon viewBox="0 0 14 17" {...props}>
    <path
      fill="currentColor"
      d="M6.99917 0.631836C5.25956 0.631836 3.69113 0.744593 2.53859 0.928543C1.96231 1.02054 1.49067 1.13409 1.14563 1.26431C0.782503 1.37266 0.488281 1.63135 0.488281 1.88123C0.983104 6.52973 1.48892 11.1846 1.99079 15.8351C2.00049 15.9369 2.07923 16.0286 2.17861 16.0538C5.22857 16.8246 8.76977 16.8246 11.8197 16.0538C11.9191 16.0286 11.9979 15.9369 12.0075 15.8351L13.5101 1.91245C13.4577 1.5289 13.1459 1.38764 12.8605 1.26431C12.5155 1.13409 12.0439 1.02054 11.4676 0.928543C10.315 0.744593 8.73878 0.631836 6.99917 0.631836H6.99917ZM6.99917 1.13159C8.71713 1.13159 10.276 1.24276 11.3893 1.42049C11.9462 1.52916 12.494 1.59943 12.9779 1.88123C12.4847 2.17435 11.8443 2.26809 11.3893 2.34184C10.276 2.51959 8.71713 2.6308 6.99917 2.6308C5.28122 2.6308 3.73014 2.51959 2.61684 2.34184C2.05881 2.23642 1.51057 2.15676 1.02042 1.88896C1.50897 1.58294 2.15867 1.49474 2.61684 1.42049C3.73014 1.24276 5.28122 1.13159 6.99917 1.13159H6.99917ZM1.05172 2.46684C1.55397 2.65514 2.106 2.76392 2.53859 2.83385C3.69113 3.01785 5.25956 3.1306 6.99917 3.1306C8.73878 3.1306 10.315 3.01785 11.4676 2.83385C11.9792 2.74257 12.4992 2.64117 12.9466 2.46684L12.5788 5.84015C12.1031 5.62392 11.5421 5.50144 11.1154 5.42627C10.052 5.24174 8.60142 5.12956 6.99917 5.12956C5.39692 5.12956 3.94639 5.24177 2.88291 5.42627C2.36601 5.52754 1.86033 5.63756 1.41953 5.84796L1.05172 2.46684ZM6.99917 5.62932C8.57788 5.62932 10.0082 5.74104 11.0294 5.91821C11.5424 6.02194 12.0419 6.10887 12.4849 6.37112C12.0661 6.68672 11.4407 6.76714 11.0294 6.83946C10.0082 7.01666 8.57788 7.12839 6.99917 7.12839C5.42046 7.12839 3.9901 7.01666 2.96899 6.83946C2.43688 6.69574 1.89661 6.61791 1.50561 6.38661C1.95006 6.09189 2.54668 5.99269 2.96899 5.91821C3.9901 5.74104 5.42046 5.62932 6.99917 5.62932H6.99917ZM2.29599 13.9532C5.3659 14.6053 8.63244 14.6053 11.7024 13.9532L11.5224 15.6008C8.65488 16.294 5.34347 16.294 2.47598 15.6008L2.29599 13.9532Z"
    />
  </Icon>
);

export default Loser;
