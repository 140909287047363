import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';
import decamelizeKeys from 'decamelize-keys';

/**
 * @type {import('axios').AxiosInstance}
 */
let apiClient = null;

export const getApiClient = () => {
  if (!apiClient) {
    throw Error(
      'Api Client is not configured yet, call configureApiClient first before using it'
    );
  }

  return apiClient;
};

export const configureApiClient = ({ baseURL, headers }) => {
  apiClient = axios.create({
    baseURL,
    headers,
  });

  apiClient.interceptors.response.use((response) => {
    if (
      response.data &&
      response.headers['content-type'].includes('application/json')
    ) {
      return {
        ...response,
        data: camelcaseKeys(response.data, { deep: true }),
      };
    }

    return response;
  });

  apiClient.interceptors.request.use((config) => {
    const newConfig = { ...config };

    if (newConfig.headers['Content-Type'] === 'multipart/form-data')
      return newConfig;

    if (config.params) {
      newConfig.params = decamelizeKeys(config.params, { deep: true });
    }

    if (config.data) {
      newConfig.data = decamelizeKeys(config.data, { deep: true });
    }

    return newConfig;
  });
};
