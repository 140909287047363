import { create } from 'zustand';

const useNavigationStore = create((set) => ({
  isNavigating: false,
  navigate: null,

  setNavigate: (navigate) => set({ navigate }),

  navigateTo: (path) => {
    set((state) => {
      state.navigate(path);

      return { ...state, isNavigating: true };
    });
  },

  setIsNavigating: (isNavigating) =>
    set((state) => ({ ...state, isNavigating })),
}));

export default useNavigationStore;
