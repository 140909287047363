import React from 'react';

import { Icon } from '@chakra-ui/react';

const AdminIcon = (props) => (
  <Icon viewBox="0 0 20 19" {...props}>
    <path
      fill="currentColor"
      d="M13.13 7.301a.7.7 0 01-.668-.488l-1.795-5.652c-.207-.65-1.127-.65-1.334 0L7.538 6.813a.7.7 0 01-.667.488H1.498c-.689 0-.964.89-.395 1.279l4.35 2.969a.7.7 0 01.272.794l-1.707 5.273c-.209.645.53 1.18 1.077.782L9.59 15.13a.7.7 0 01.823 0l4.505 3.27c.548.398 1.286-.138 1.077-.782l-1.708-5.277a.7.7 0 01.27-.793l4.345-2.97c.568-.388.293-1.278-.396-1.278H13.13z"
    />
  </Icon>
);

export default AdminIcon;
