export const GAMEROOM_CLOSED = 'gameroom:closed';
export const GAMEROOM_PLAYER_JOINED = 'gameroom:player_joined';
export const GAMEROOM_PLAYER_LEFT = 'gameroom:player_left';
export const GAMEROOM_PICKING_NEW_PACK = 'gameroom:picking_new_pack';
export const GAME_STARTED = 'game:started';
export const GAME_FINISHED = 'game:finished';
export const GAME_CANCELED = 'game:canceled';
export const GAME_ROUND_STARTED = 'game:round_started';
export const GAME_ROUND_FINISHED = 'game:round_finished';
export const GAME_ROUND_ANSWER_CREATED = 'game:round_answer_created';
