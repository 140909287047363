import React from 'react';
import { Icon } from '@chakra-ui/react';

const Crown = (props) => (
  <Icon viewBox="0 0 18 16" paddingX="0.2rem" {...props}>
    <circle cx="16.5591" cy="5.22618" r="0.652821" fill="currentColor" />
    <path
      d="M8.62743 2.03503L5.75113 7.62176L2.35567 5.84155C2.23212 6.0114 2.08243 6.13999 1.91406 6.21892L3.65059 13.1346H14.3442L16.0808 6.21892C15.9124 6.13999 15.7627 6.0114 15.6391 5.84155L12.2437 7.62176L9.36739 2.03503C9.24919 2.08262 9.12623 2.10887 8.99741 2.10887C8.86861 2.10887 8.74563 2.08262 8.62743 2.03503ZM3.65059 13.6597V15.2348H14.3442V13.6597H3.65059Z"
      fill="currentColor"
    />
    <circle cx="3.43993" cy="9.22618" r="0.652821" fill="currentColor" />
    <circle cx="1.43993" cy="5.22618" r="0.652821" fill="currentColor" />
    <circle cx="8.99861" cy="0.84981" r="0.82088" fill="currentColor" />
  </Icon>
);

export default Crown;
