import { useLocation } from 'react-router-dom';

import { PLAYER_INFO } from '../constants/key';
import paths from '../constants/paths';

import useGameroomStore from './useGameroomStore';
import useNavigationStore from './useNavigationStore';

const useNavigation = () => {
  const gameroom = useGameroomStore((state) => state.gameroom);
  const playerInfo = JSON.parse(localStorage.getItem(PLAYER_INFO));
  const location = useLocation();
  const { navigateTo } = useNavigationStore();

  const handleNavigation = () => {
    if (!gameroom) return;
    if (!playerInfo) return;

    const { id: playerId } = playerInfo;
    const { currentGame } = gameroom;

    if (!currentGame) {
      return;
    }

    const { rounds, scores } = currentGame;
    const currentRound = rounds[rounds.length - 1];

    if (scores) {
      if (
        location.pathname !== paths.scores &&
        location.pathname !== paths.packs
      ) {
        navigateTo(paths.scores);
      }
      return;
    }

    if (currentRound) {
      if (currentRound.finished) {
        if (location.pathname !== paths.result) {
          navigateTo(paths.result);
        }
        return;
      }

      const currentPlayerAnswer = currentRound.answers.find(
        (answer) => answer.playerId === Number(playerId)
      );

      if (currentPlayerAnswer) {
        if (location.pathname !== paths.answers) {
          navigateTo(paths.answers);
        }
      } else if (location.pathname !== paths.round) {
        navigateTo(paths.round);
      }
    }
  };

  return { handleNavigation };
};

export default useNavigation;
