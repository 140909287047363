import {
  Box,
  Text,
  Flex,
  Center,
  Image,
  useMultiStyleConfig,
} from '@chakra-ui/react';
import React from 'react';
import PropTypes from 'prop-types';

import { PackShape } from '@utils/propsShape';

const Pack = ({ pack, variant, onClick }) => {
  const colors = [pack.personaA.color, pack.personaB.color];

  const styles = useMultiStyleConfig('Pack', {
    variant,
    colors,
  });

  return (
    <Box sx={styles.gradientBorder} onClick={onClick} width="fit-content">
      <Flex sx={styles.container}>
        <Image src={pack.image} alt="pack" sx={styles.image} />
        <Center sx={styles.textContainer}>
          <Text sx={styles.personaName}>{pack.personaA.name}</Text>
          <Text sx={styles.textVS}>VS</Text>
          <Text sx={styles.personaName}>{pack.personaB.name}</Text>
        </Center>
      </Flex>
    </Box>
  );
};

Pack.propTypes = {
  pack: PackShape.isRequired,
  variant: PropTypes.string,
  onClick: PropTypes.func,
};

export default Pack;
