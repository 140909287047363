import PropTypes from 'prop-types';

export const PersonaShape = PropTypes.shape({
  name: PropTypes.string,
  avatar: PropTypes.string,
  color: PropTypes.string,
  secondaryColor: PropTypes.string,
  variant: PropTypes.string,
  onImageClick: PropTypes.func,
});

export const PackShape = PropTypes.shape({
  image: PropTypes.string.isRequired,
  personaA: PersonaShape.isRequired,
  personaB: PersonaShape.isRequired,
});

const PlayerItemAnswerShape = PropTypes.shape({
  status: PropTypes.oneOf(['answered', 'unanswered', 'correct', 'incorrect'])
    .isRequired,
  persona: PersonaShape,
});

const PlayerItemLeaderboardShape = PropTypes.shape({
  position: PropTypes.oneOf(['firstPlace', 'lastPlace', 'other']).isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
});

export const PlayerItemShape = PropTypes.shape({
  username: PropTypes.string.isRequired,
  isCurrentPlayer: PropTypes.bool,
  isAdmin: PropTypes.bool,
  variant: PropTypes.oneOf(['answer', 'leaderBoard']),
  roomProps: PropTypes.oneOf([
    PlayerItemAnswerShape,
    PlayerItemLeaderboardShape,
  ]),
});

export const PlayerListShape = PropTypes.shape({
  players: PlayerItemShape.isRequired,
  variant: PropTypes.string,
});
