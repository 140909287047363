import { useMutation } from '@tanstack/react-query';

import { submitAnswer } from '../api/round';

const useSubmitAnswer = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ roundId, answerParams }) =>
      submitAnswer(roundId, answerParams),
    onSuccess,
  });
};

export default useSubmitAnswer;
