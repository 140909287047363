import React from 'react';
import { Icon } from '@chakra-ui/react';

const Correct = (props) => (
  <Icon viewBox="0 0 18 16" {...props}>
    <path
      fill="currentColor"
      d="M15.0743 0.723145L6.91643 9.45749L2.92568 5.18465L0 8.31749L6.91478 15.7231L18 3.85483L15.0743 0.723145Z"
    />
  </Icon>
);
export default Correct;
