import React, { Fragment } from 'react';
import { Stack, useMultiStyleConfig, Image } from '@chakra-ui/react';

import DottedBorder from '../assets/dotted-border.svg';

import PlayerItem from './PlayerItem';

import { PlayerListShape } from '@utils/propsShape';

const PlayerList = ({ players, variant }) => {
  const style = useMultiStyleConfig('PlayerList', { variant });
  return (
    <Stack {...style.container}>
      {players.map((player, idx) => {
        return (
          <Fragment key={idx}>
            <PlayerItem {...player} />
            {idx !== players.length - 1 && (
              <Image src={DottedBorder} alt="border" minH="0.25rem" />
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
};

PlayerList.propTypes = PlayerListShape;

export default PlayerList;
