import { getApiClient } from '..';

export const buildNextRound = async (gameId) => {
  const response = await getApiClient().post(`/games/${gameId}/rounds`);

  return response.data;
};

export const submitAnswer = async (roundId, answerParams) => {
  const response = await getApiClient().post(`/rounds/${roundId}/answers`, {
    answer: {
      ...answerParams,
    },
  });
  return response.data;
};

export const finishRound = async (gameId, roundId) => {
  const response = await getApiClient().put(
    `/games/${gameId}/rounds/${roundId}`,
    {
      finished: true,
    }
  );

  return response.data;
};
