import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'content',
  'title',
  'playerList',
  'winner',
  'adminButtons',
  'footer',
]);

const Leaderboard = helpers.defineMultiStyleConfig({
  baseStyle: {
    background: {
      bg: 'main.purple.darker',
      paddingY: '2.4rem',
      gap: '1rem',
      borderRadius: '2rem',
    },
    content: {
      gap: '0rem',
      alignItems: 'center',
      height: '100%',
      maxHeight: '65rem',
    },
    container: {
      gap: '2.4rem',
      alignItems: 'center',
      height: 'fit-content',
      flexDirection: 'column',
    },
    title: {
      marginX: '3.2rem',
      gap: '0.8rem',
      marginBottom: '4.1rem',
    },
    winnerContainer: {
      flexDirection: 'column',
      overflowY: 'auto',
      overflowX: 'hidden',
      alignItems: 'center',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      marginBottom: '1rem',
      width: '100%',
    },
    winner: {
      backgroundColor: 'secondary.lilac.baseAlpha30',
      paddingY: '0.8rem',
      paddingX: '3.2rem',
      width: '100%',
    },
    adminButtons: {
      alignItems: 'center',
      gap: '0.8rem',
      width: '100%',
      marginTop: '3.2rem',
    },
    footer: {
      alignContent: 'center',
      minHeight: '2.6rem',
      color: 'white',
    },
  },
});

export default Leaderboard;
