import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { GAMEROOM_KEY } from '../constants/key';

import useFetchGameroom from '../hooks/useFetchGameroom';
import useGameroomStore from '../hooks/useGameroomStore';
import useNavigation from '../hooks/useNavigation';
import useCableStore from '../hooks/useCableStore';
import useNavigationStore from '../hooks/useNavigationStore';

import Loading from './Loading';

const GameroomWrapper = () => {
  const { isConnected } = useCableStore();
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const { data: fetchedGameroom, isLoading } = useFetchGameroom(gameroomKey);
  const updateGameroom = useGameroomStore((state) => state.updateGameroom);
  const gameroom = useGameroomStore((state) => state.gameroom);
  const isNavigating = useNavigationStore((state) => state.isNavigating);
  const setIsNavigating = useNavigationStore((state) => state.setIsNavigating);
  const location = useLocation();

  const { handleNavigation } = useNavigation();

  useEffect(() => {
    updateGameroom(fetchedGameroom);
  }, [fetchedGameroom, updateGameroom]);

  useEffect(() => {
    handleNavigation();
  }, [gameroom]);

  useEffect(() => {
    setIsNavigating(false);
  }, [location, setIsNavigating]);

  if (!isConnected) {
    return (
      <Loading
        title="Trying to reconnect..."
        description="Make sure you’re online."
      />
    );
  }

  if (isLoading || !gameroom || isNavigating) {
    return <Loading title="Loading..." description="Wait a few moments." />;
  }

  return <Outlet />;
};

export default GameroomWrapper;
