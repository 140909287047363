import modalInfos from '../constants/modalInfos';

export const openModal = (modalInfoKey, onConfirm) => {
  return {
    ...modalInfos[modalInfoKey],
    isOpen: true,
    onConfirm,
  };
};

export const closedModal = () => {
  return {
    isOpen: false,
    title: '',
    description: '',
    confirmText: '',
    onConfirm: () => {},
  };
};
