import { useMutation } from '@tanstack/react-query';

import { createGame } from '../api/game';
import { GAMEROOM_KEY } from '../constants/key';

const useCreateGame = ({ onSuccess, onError } = {}) => {
  return useMutation({
    mutationFn: async (packId) => {
      const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
      const game = await createGame(gameroomKey, packId);

      return { game };
    },
    onSuccess,
    onError,
  });
};

export default useCreateGame;
