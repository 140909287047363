import React, { useState, useCallback } from 'react';
import { Button, Center, Stack, Text } from '@chakra-ui/react';

import useGameroomStore from '../hooks/useGameroomStore';
import TitleDescription from '../components/TitleDescription';
import Persona from '../components/Persona';
import LeaveGameButton from '../components/LeaveGameButton';
import { GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useCancelGame from '../hooks/useCancelGame';
import Modal from '../components/Modal';
import useBuildNextRound from '../hooks/useBuildNextRound';
import { openModal, closedModal } from '../utils/modal';
import useFinishGame from '../hooks/useFinishGame';
import PlayerList from '../components/PlayerList';

const Result = () => {
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const playerInfo = JSON.parse(localStorage.getItem(PLAYER_INFO));
  const { admin } = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const [modalInfo, setModalInfo] = useState(closedModal());

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const { mutate: cancelGame } = useCancelGame();

  const {
    currentGame: {
      id: gameId,
      players,
      length: numberRounds,
      rounds: {
        length: currentRoundNumber,
        [currentRoundNumber - 1]: { rightAnswer, ...currentRound },
      },
    },
  } = useGameroomStore((state) => state.gameroom);

  const { mutate: buildNextRound } = useBuildNextRound();

  const { mutate: finishGame } = useFinishGame();

  const handleLeave = () => {
    if (!gameId) return;

    if (playerInfo.admin) {
      cancelGame({
        gameroomKey,
        gameId,
      });
    } else {
      destroyPlayer({
        gameroomKey,
        playerId: playerInfo.id,
      });
    }
  };

  const getPlayerAnswer = (playerId) => {
    return currentRound.answers.find((answer) => answer.playerId === playerId);
  };

  const isAnswerCorrect = useCallback(
    (playerAnswer) => {
      return playerAnswer && rightAnswer.id === playerAnswer.personaId;
    },
    [rightAnswer.id]
  );

  const isCurrentPlayerCorrect = isAnswerCorrect(
    getPlayerAnswer(playerInfo.id)
  );

  const handleNextQuote = () => {
    if (!admin) return;

    if (currentRoundNumber < numberRounds) {
      buildNextRound({ gameId });
    } else {
      finishGame({
        gameroomKey,
        gameId,
      });
    }
  };

  return (
    <Center height="100vh">
      <Stack align="center">
        <Stack width="41.9rem" gap="2rem" align="center">
          <Text variant="count">
            {currentRoundNumber}/{numberRounds}
          </Text>
          <Stack
            paddingX="3.2rem"
            paddingY="2.4rem"
            gap="3.2rem"
            borderRadius="2rem"
            bg="main.purple.darker"
            align="center"
          >
            <Stack height="53.0rem" gap="4.8rem" align="center">
              <Stack height="30rem" gap="2.4rem" align="center">
                <TitleDescription
                  title={
                    isCurrentPlayerCorrect ? "You're a genius!" : 'Oh, snap!'
                  }
                  description={`“${currentRound.quote}“`}
                  variant="answer"
                  isCorrect={isCurrentPlayerCorrect}
                />
                <Persona
                  name={rightAnswer.name}
                  avatar={rightAnswer.avatar}
                  color={rightAnswer.color}
                  secondaryColor={rightAnswer.secondaryColor}
                />
              </Stack>
              <PlayerList
                playerItemVariant="answer"
                players={players.map((player) => {
                  const isPlayerCorrect = isAnswerCorrect(
                    getPlayerAnswer(player.id)
                  );
                  return {
                    username: player.username,
                    isAdmin: player.admin,
                    isCurrentPlayer: player.username === playerInfo.username,
                    leftGameroomAt: player.leftGameroomAt,
                    roomProps: {
                      status: isPlayerCorrect ? 'correct' : 'incorrect',
                    },
                    variant: 'answer',
                  };
                })}
              />
            </Stack>
            {admin && (
              <Stack minWidth="35.5rem" gap="0.8rem" align="center">
                <Button
                  variant="solid"
                  scheme="main.purple"
                  onClick={handleNextQuote}
                >
                  {currentRoundNumber === numberRounds
                    ? 'show game scores'
                    : 'next quote'}
                </Button>
                <Button
                  variant="outline"
                  scheme="secondary.pink"
                  onClick={() =>
                    setModalInfo(openModal('end_game', handleLeave))
                  }
                >
                  end game
                </Button>
              </Stack>
            )}
          </Stack>
        </Stack>
        {!admin && (
          <LeaveGameButton
            onClick={() => setModalInfo(openModal('leave_game', handleLeave))}
            isAdmin={playerInfo.admin}
          />
        )}
      </Stack>
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default Result;
