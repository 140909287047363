import React, { useState } from 'react';
import { Center, HStack, Stack, Text, Button } from '@chakra-ui/react';

import Persona from '../components/Persona';
import LeaveGameButton from '../components/LeaveGameButton';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useSubmitAnswer from '../hooks/useSubmitAnswer';
import useCancelGame from '../hooks/useCancelGame';
import { GAMEROOM_KEY, PLAYER_INFO } from '../constants/key';
import Modal from '../components/Modal';
import useGameroomStore from '../hooks/useGameroomStore';
import { closedModal, openModal } from '../utils/modal';

const Round = () => {
  const [selectedPersona, setSelectedPersona] = useState(null);
  const playerInfo = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const [modalInfo, setModalInfo] = useState(closedModal());

  const {
    currentGame: {
      id: gameId,
      length: numberRounds,
      rounds: {
        length: currentRoundNumber,
        [currentRoundNumber - 1]: currentRound,
      },
      pack,
    },
  } = useGameroomStore((state) => state.gameroom);

  const cancelGame = useCancelGame();
  const leaveRoom = useDestroyPlayer();
  const submitAnswer = useSubmitAnswer();

  const handleSubmitAnswer = () => {
    if (!selectedPersona) {
      return;
    }

    const { id } = playerInfo;
    const roundId = currentRound.id;
    const answerParams = {
      playerId: id,
      personaId: selectedPersona,
    };
    submitAnswer.mutate({ roundId, answerParams });
  };

  const handleLeaveRoom = () => {
    const { id, admin } = playerInfo;
    const gameroomKey = localStorage.getItem(GAMEROOM_KEY);

    if (admin) {
      cancelGame.mutate({
        gameroomKey,
        gameId,
      });
    } else {
      leaveRoom.mutate({ gameroomKey, playerId: id });
    }
  };

  const handleOpenModal = () => {
    if (playerInfo.admin) {
      setModalInfo(openModal('end_game', handleLeaveRoom));
    } else {
      setModalInfo(openModal('leave_game', handleLeaveRoom));
    }
  };

  return (
    <Center height="100vh">
      <Stack align="center">
        <Stack width="41.9rem" gap="2rem" align="center">
          <Text variant="count">
            {currentRoundNumber}/{numberRounds}
          </Text>
          <Stack width="full" gap="3.2rem" align="center">
            <Stack
              height="68.9rem"
              width="full"
              bg="main.purple.darker"
              paddingX="3.2rem"
              paddingY="2.4rem"
              gap="24rem"
              borderRadius="2rem"
              align="center"
            >
              <Center
                width="35.5rem"
                height="10rem"
                paddingX="1.6rem"
                paddingY="2.4rem"
                gap="4.8rem"
              >
                <Text variant="quote" width="32.3rem" height="5.2rem">
                  “{currentRound.quote}“
                </Text>
              </Center>
              <Stack
                width="31.1rem"
                height="30.1rem"
                gap="3.2rem"
                align="center"
              >
                <Stack width="full" height="22.9rem" gap="3.2rem">
                  <Text textColor="white" variant="whoSaidIt">
                    Who said it?
                  </Text>
                  <HStack width="full" height="17.5rem" gap="3.3rem">
                    <Persona
                      name={pack.personaA.name}
                      avatar={pack.personaA.avatar}
                      color={pack.personaA.color}
                      secondaryColor={pack.personaA.secondaryColor}
                      onImageClick={() => setSelectedPersona(pack.personaA.id)}
                      variant={
                        selectedPersona === pack.personaA.id
                          ? 'selected'
                          : 'notSelected'
                      }
                    />
                    <Persona
                      name={pack.personaB.name}
                      avatar={pack.personaB.avatar}
                      color={pack.personaB.color}
                      secondaryColor={pack.personaB.secondaryColor}
                      variant={
                        selectedPersona === pack.personaB.id
                          ? 'selected'
                          : 'notSelected'
                      }
                      onImageClick={() => setSelectedPersona(pack.personaB.id)}
                    />
                  </HStack>
                </Stack>
                <Button
                  variant="solid"
                  scheme="main.purple"
                  onClick={handleSubmitAnswer}
                  isDisabled={!selectedPersona}
                >
                  submit answer
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <LeaveGameButton onClick={handleOpenModal} isAdmin={playerInfo.admin} />
      </Stack>
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default Round;
