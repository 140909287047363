const Modal = {
  baseStyle: {
    overlay: {
      bg: 'main.purple.darkestAlpha70',
    },
    dialog: {
      borderRadius: '2rem',
      bg: 'main.purple.darker',
      paddingY: '2.4rem',
      paddingX: '3.2rem',
    },
    body: {
      alignItems: 'center',
      padding: '0',
    },
    footer: {
      marginTop: '3.2rem',
      gap: '0.8rem',
      display: 'flex',
      p: '0',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  sizes: {
    xl: {
      dialog: {
        maxW: '34.3rem',
      },
    },
  },
  defaultProps: {
    size: 'xl',
    isCentered: true,
  },
};

export default Modal;
