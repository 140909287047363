import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';

import useNavigationStore from '../hooks/useNavigationStore';

const NavigationWrapper = () => {
  const navigate = useNavigate();
  const setNavigate = useNavigationStore((state) => state.setNavigate);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate, setNavigate]);

  return <Outlet />;
};

export default NavigationWrapper;
