const modalInfos = {
  reveal_answer: {
    title: 'Reveal answer?',
    description: "Other players won't be able to respond anymore.",
    confirmText: 'confirm',
    showCheckbox: true,
  },
  end_game: {
    title: 'End Game?',
    description:
      "Doing this will end the ongoing game for all players, but won't close the game room",
    confirmText: 'end',
  },
  leave_game: {
    title: 'Leave Game?',
    description: 'Doing this will kick you from the ongoing game',
    confirmText: 'leave',
  },
};

export default modalInfos;
