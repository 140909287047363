import { getApiClient } from '..';

export const createGame = async (gameroomKey, packId) => {
  const response = await getApiClient().post(
    `/gamerooms/${gameroomKey}/games`,
    { game: { packId } }
  );
  return response.data;
};

export const cancelGame = async (gameroomKey, gameId) => {
  const response = await getApiClient().put(
    `/gamerooms/${gameroomKey}/games/${gameId}`,
    {
      canceledAt: new Date(),
    }
  );
  return response.data;
};

export const finishGame = async (gameroomKey, gameId) => {
  const response = await getApiClient().put(
    `gamerooms/${gameroomKey}/games/${gameId}`,
    {
      finishedAt: new Date(),
    }
  );
  return response.data;
};
