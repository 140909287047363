import React, { useState } from 'react';
import { Center, Stack, Text, Button } from '@chakra-ui/react';

import Persona from '../components/Persona';
import PlayerList from '../components/PlayerList';
import TitleDescription from '../components/TitleDescription';
import { GAMEROOM_KEY, PLAYER_INFO, CHECKBOX_STATUS } from '../constants/key';
import Logout from '../assets/Logout.svg';
import Modal from '../components/Modal';
import useFinishRound from '../hooks/useFinishRound';
import { openModal, closedModal } from '../utils/modal';
import useCancelGame from '../hooks/useCancelGame';
import useDestroyPlayer from '../hooks/useDestroyPlayer';
import useGameroomStore from '../hooks/useGameroomStore';

const Answers = () => {
  const [modalInfo, setModalInfo] = useState(closedModal());
  const gameroomKey = localStorage.getItem(GAMEROOM_KEY);
  const { id, username, admin } = JSON.parse(localStorage.getItem(PLAYER_INFO));

  const {
    currentGame: {
      id: gameId,
      players,
      length: numberRounds,
      rounds: {
        length: currentRoundNumber,
        [currentRoundNumber - 1]: currentRound,
      },
      pack,
    },
  } = useGameroomStore((state) => state.gameroom);

  const { mutate: destroyPlayer } = useDestroyPlayer();

  const { mutate: finishRound } = useFinishRound();

  const { mutate: cancelGame } = useCancelGame();

  const findPlayerAnswer = (playerId) => {
    return currentRound.answers.find((answer) => answer.playerId === playerId);
  };

  const submitFinishRound = () => {
    finishRound({ gameId, roundId: currentRound.id });
  };

  const revealAnswer = (checkboxChecked) => {
    if (checkboxChecked) localStorage.setItem(CHECKBOX_STATUS, true);
    submitFinishRound();
  };

  const endGame = () => {
    cancelGame({ gameroomKey, gameId });
  };

  const leaveGame = () => {
    destroyPlayer({ gameroomKey, playerId: id });
  };

  const handleRevealAnswer = () => {
    const checkboxStatus = localStorage.getItem(CHECKBOX_STATUS);
    if (checkboxStatus || currentRound.answers.length === players.length) {
      submitFinishRound();
    } else {
      setModalInfo(openModal('reveal_answer', revealAnswer));
    }
  };

  return (
    <Center height="100vh">
      <Stack gap="2rem" align="center" maxW="41.9rem">
        <Text variant="count">
          {currentRoundNumber}/{numberRounds}
        </Text>
        <Stack
          borderRadius="2rem"
          paddingX="3.2rem"
          paddingY="2.4rem"
          gap="3.2rem"
          bgColor="main.purple.darker"
        >
          <Stack gap="4.8rem">
            <Stack gap="2.4rem">
              <TitleDescription
                variant="answers"
                title="Waiting for other players..."
                description={`“${currentRound.quote}“`}
              />
              <Persona />
            </Stack>
            <PlayerList
              playerItemVariant="answer"
              players={players.map((player) => {
                const answer = findPlayerAnswer(player.id);
                let persona = null;
                if (answer) {
                  const { personaA, personaB } = pack;
                  persona =
                    personaA.id === answer.personaId ? personaA : personaB;
                }

                return {
                  username: player.username,
                  isAdmin: player.admin,
                  isCurrentPlayer: username === player.username,
                  leftGameroomAt: player.leftGameroomAt,
                  roomProps: {
                    status: persona ? 'answered' : 'unanswered',
                    persona,
                  },
                  variant: 'answer',
                };
              })}
            />
          </Stack>
          {admin && (
            <Stack align="center" gap="0.8rem">
              <Button
                variant="solid"
                scheme="main.purple"
                onClick={handleRevealAnswer}
              >
                reveal answer
              </Button>
              <Button
                variant="outline"
                scheme="secondary.pink"
                onClick={() => setModalInfo(openModal('end_game', endGame))}
              >
                end game
              </Button>
            </Stack>
          )}
        </Stack>
        {!admin && (
          <Center
            borderRadius="3.2rem"
            paddingX="2.4rem"
            paddingY="1.2rem"
            gap="1rem"
            cursor="pointer"
            onClick={() => setModalInfo(openModal('leave_game', leaveGame))}
            position="absolute"
            bottom="0rem"
            marginBottom="3.2rem"
          >
            <img src={Logout} alt="logout-icon" />
            <Text textColor="secondary.pink.light" variant="leave">
              leave game
            </Text>
          </Center>
        )}
      </Stack>
      <Modal {...modalInfo} onClose={() => setModalInfo(closedModal())} />
    </Center>
  );
};

export default Answers;
