const Button = {
  sizes: {
    lg: {
      borderRadius: '2.5rem',
      width: 'full',
      maxW: '27.9rem',
      height: '4.0rem',
      lineHeight: '2.611rem',
      fontSize: '1.6rem',
      paddingX: '2.4rem',
      paddingY: '1.2rem',
    },
  },
  baseStyle: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  variants: {
    solid: ({ scheme }) => ({
      color: 'white',
      bgColor: `${scheme}.light`,

      _hover: {
        bgColor: `${scheme}.base`,

        _disabled: {
          bgColor: `${scheme}.light`,
        },
      },

      _active: {
        bgColor: `${scheme}.dark`,
      },
    }),
    outline: ({ scheme }) => ({
      color: 'white',
      border: '1px',
      borderColor: `${scheme}.light`,

      _hover: {
        bgColor: `${scheme}.base`,
        borderColor: `${scheme}.base`,

        _disabled: {
          borderColor: `${scheme}.light`,
        },
      },

      _active: {
        bgColor: `${scheme}.dark`,
      },
    }),
  },

  defaultProps: {
    size: 'lg',
  },
};

export default Button;
