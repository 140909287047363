import {
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';

const Input = ({ label, placeholder, errors, value, onChange }) => {
  return (
    <FormControl isInvalid={!!errors}>
      <FormLabel>{label}:</FormLabel>
      <ChakraInput
        type="text"
        variant="custom"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <FormErrorMessage>{errors}</FormErrorMessage>
    </FormControl>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.string,
};

export default Input;
