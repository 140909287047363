import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import { Box } from '@chakra-ui/react';

import wavesUpAnimation from '../../assets/animations/wavesup.json';
import wavesDownAnimation from '../../assets/animations/wavesdown.json';

const WavesAnimation = () => {
  const containerStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
  };

  const waveStyle = {
    width: '100vw',
    position: 'absolute',
    zIndex: 0,
  };

  const wavesRef = useRef();

  useEffect(() => {
    if (wavesRef.current) {
      wavesRef.current.setSpeed(0.9);
    }
  }, []);

  return (
    <Box style={containerStyle}>
      <Lottie
        animationData={wavesUpAnimation}
        style={{ ...waveStyle, top: '-5%' }}
        loop={false}
        lottieRef={wavesRef}
      />
      <Lottie
        animationData={wavesDownAnimation}
        style={{ ...waveStyle, bottom: '-5%' }}
        loop={false}
        lottieRef={wavesRef}
      />
    </Box>
  );
};

export default WavesAnimation;
