import { getApiClient } from '..';

const createPlayer = async (gameroomKey, playerParams) => {
  const response = await getApiClient().post(
    `/gamerooms/${gameroomKey}/players`,
    playerParams
  );
  return response.data;
};

const destroyPlayer = async (gameroomKey, playerId) => {
  const response = await getApiClient().delete(
    `/gamerooms/${gameroomKey}/players/${playerId}`
  );
  return response.data;
};

export { createPlayer, destroyPlayer };
