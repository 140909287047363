import { useMutation } from '@tanstack/react-query';

import { destroyGameroom } from '../api/gameroom';

const useDestroyGameroom = ({ onSuccess } = {}) => {
  return useMutation({
    mutationFn: ({ gameroomKey }) => destroyGameroom(gameroomKey),
    onSuccess,
  });
};

export default useDestroyGameroom;
