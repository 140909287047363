export const Form = {
  baseStyle: {
    container: {
      label: {
        marginBottom: '0.8rem',
        fontWeight: 400,
        textColor: 'secondary.lilac.base',
        lineHeight: '1.877rem',
        fontSize: '1.6rem',
        textAlign: 'center',
        marginRight: 0,
      },
    },
  },
  sizes: {
    lg: {
      container: {
        width: '27.9rem',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
};

export const FormError = {
  baseStyle: {
    text: {
      color: 'secondary.pink.light',
      fontWeight: 400,
      justifyContent: 'center',
      fontSize: '1.2rem',
      lineHeight: '1.306rem',
      textAlign: 'center',
      marginTop: '0.8rem',
    },
  },
};
